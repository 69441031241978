import { addClass, addCss } from '../lib/ReactUtils'


addClass('plans-container', 'flex justify-between flex-col lg:flex-row gap-2 items-center lg:items-stretch tracking-snug relative mt-18')
addClass('plans-plan', 'w-full max-w-sm text-center px-8 py-2 rounded-lg shadow-md relative text-gray-900 bg-white flex flex-col border-2 border-gray-200')
addClass('plans-plan-highlight', 'rounded-t-lg absolute top-0 inset-x-0 h-2')
addClass('plans-plan-header', 'flex flex-col uppercase leading-relaxed py-8')
addClass('plans-plan-name', 'font-bold text-xl')
addClass('plans-plan-price', 'font-bold text-4xl sm:text-5xl my-1')
addClass('plans-plan-duration', 'text-gray-500 font-bold tracking-widest')
addClass('plans-plan-features', 'flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1')
addClass('plans-plan-feature-main', 'mt-5 first:mt-0 text-xl font-bold tracking-wide')
addClass('plans-plan-feature', 'mt-3 first:mt-0 font-medium text-xl lg:text-lg lg:text-xl')
addClass('plans-plan-action', 'px-4 py-8')

addCss(`
  .plans-plan-featured {
    @apply bg-gradient-to-br from-purple-700 to-purple-600 text-white !important;
  }
  .plans-plan-featured .plans-plan-features,
  .plans-plan-featured .plans-plan-header,
  .plans-plan-featured .plans-plan-duration
  {
    @apply border-white text-white !important;
  }
`)
