import { addClass } from '../lib/ReactUtils'


addClass('faqs-container', 'mt-5 sm:mt-10 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto')
addClass('faqs-col', 'w-full lg:mr-12 last:mr-0')
addClass('faqs-faq', 'select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 pt-2 pb-6')
addClass('faqs-question', 'flex justify-between items-center')
addClass('faqs-question-text', 'text-sm sm:text-lg font-semibold text-gray-700')
addClass('faqs-question-icon', 'ml-2 transition duration-300')
addClass('faqs-question-icon-svg', 'transition duration-200 transform w-6 h-6')
addClass('faqs-question-icon-svg-selected', 'transition duration-200 transform w-6 h-6 rotate-180')
addClass('faqs-answer', 'pointer-events-none max-h-0 transition duration-200 opacity-0 text-sm font-normal mt-2 text-gray-600')
addClass('faqs-answer-selected', 'max-h-30 transition duration-200 opacity-100 text-sm font-normal mt-2 text-gray-600')
