import React from "react"
import { useClasses, useQueryParam } from '../lib/ReactUtils'
import { useLocation } from '../components/GeoLocation'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Plans from '../components/Plans'
import FAQs from '../components/FAQs'
import CTA from '../components/CTA'
import { Link } from "gatsby"
import FAQS from '../json/faqs/pricing.json'
import '../styles/ToggleButton'

import OO from '../images/comparison/oneoffice.svg'
import MS from '../images/comparison/microsoft.svg'
import GOOGLE from '../images/comparison/google.svg'

const OO_PLANS = [
  {
    name: 'OneOffice Lite',
    price: '$3.99',
    duration: 'Monthly',
    mainFeature: 'Startup / Small Office',
    features: [
      '100G combined Email / Drive',
      'Word, Powerpoint, Excel',
      'Military-grade Encryption',
      'Offline document editors',
      'Basic email support'
    ],
    button: 'Start now',
    buttonClasses: '',
    buttonTarget: '/buy'
  },
  {
    name: 'Standard',
    price: '$8.99',
    duration: 'Monthly',
    mainFeature: 'Medium Organizations',
    features: [
      '1TB Data Storage',
      '50G Email Storage',
      'Private servers',
      'Work groups',
      'Migration support',
      'Webinars (100+)',
      'Business SLA support'  
    ],
    featured: true,
    button: 'Buy now',
    buttonClasses: 'cta',
    buttonTarget: '/buy'
  },
  {
    name: 'Enterprise',
    price: '$13.99',
    duration: 'Monthly',
    mainFeature: 'Larger Organizations',
    features: [
      '2TB Data Storage',
      '100G Email Storage',
      'Ransomware  / loss prevention',
      'Real-time audits',
      'Hybrid servers',
      'Versioned-backups',
      'Webinars (1000 users)',
      '1-hour support'
    ],
    button: 'Contact Us',
    buttonClasses: 'transparent',
    buttonTarget: '/buy'
  }
]

const MS_PLANS = [
  {
    name: 'MS Lite',
    price: '$5',
    duration: 'Monthly',
    mainFeature: 'Home Office',
    features: [
      'Email',
      {label:'Online-only Editors', className:'font-bold text-red-600'},
      'Basic email support',
      'No Database',
      'No Projects',
      {label:'No data loss prevention', className:'font-bold text-red-600'},
      {label:'Basic Security Controls', className:'font-bold text-red-600'},
    ],
    button: 'Compute Savings',
    buttonClasses: 'transparent',
    buttonTarget: '/savings'
  },
  {
    name: 'MS Business',
    price: '$20',
    duration: 'Monthly',
    mainFeature: 'Business',
    features: [
      'Email',
      {label:'Desktop Editors', className:'font-bold'},
      'Phone support',
      'Databases',
      'No Projects',
      {label:'Threat detection', className:'font-bold'},
      {label:'Advanced Security Controls', className:'font-bold'},
    ],
    button: 'Compute Savings',
    buttonClasses: 'transparent',
    buttonTarget: '/savings'
  },
  {
    name: 'OO Business',
    price: '$8.99',
    duration: 'Monthly',
    mainFeature: 'Small / Medium Enterprise',
    features: [
      'Email',
      {label:'Desktop Editors', className:'font-bold text-amber-300'},
      'Same-day Support',
      'Databases',
      {label:'Projects', className:'font-bold text-amber-300'},
      {label:'Data loss prevention', className:'font-bold text-amber-300'},
      {label:'Military-Grade Security', className:'font-bold text-amber-300'},
      {label:'Private Secure Server', className:'font-bold text-amber-300'},
      {label:'End-to-end Encryption', className:'font-bold text-amber-300'},
      {label:'Free Hybrid Hosting', className:'font-bold text-amber-300'}
    ],
    featured: true,
    button: 'Buy now',
    buttonClasses: 'cta',
    buttonTarget: '/buy'
  },
]


const GOOGLE_PLANS = [
  {
    name: 'Google Starter',
    price: '$6',
    duration: 'Monthly',
    mainFeature: 'Home Office',
    features: [
      'Email',
      {label:'Online-only Editors', className:'font-bold text-red-600'},
      {label:'30G', className:'font-bold text-red-600'},
      'Basic email support',
      'No Database',
      'No Projects',
      {label:'No data loss prevention', className:'font-bold text-red-600'},
      {label:'Basic Security Controls', className:'font-bold text-red-600'},
    ],
    button: 'Compute Savings',
    buttonClasses: 'transparent',
    buttonTarget: '/savings'
  },
  {
    name: 'Google Business+',
    price: '$18',
    duration: 'Monthly',
    mainFeature: 'Business',
    features: [
      'Email',
      {label:'Online-only Editors', className:'font-bold text-red-600'},
      '1TB+',
      'Basic email support',
      'No Database',
      'No Projects',
      {label:'No data loss prevention', className:'font-bold text-red-600'},
      {label:'Improved Security Controls', className:'font-bold text-red-600'},
    ],
    button: 'Compute Savings',
    buttonClasses: 'transparent',
    buttonTarget: '/savings'
  },
  {
    name: 'OO Business',
    price: '$8.99',
    duration: 'Monthly',
    mainFeature: 'Small / Medium Enterprise',
    features: [
      'Email',
      {label:'Desktop Editors', className:'font-bold text-amber-300'},
      '1TB+',
      'Same-day Support',
      'Databases',
      {label:'Projects', className:'font-bold text-amber-300'},
      {label:'Ransomware / loss prevention', className:'font-bold text-amber-300'},
      {label:'Military-Grade Security', className:'font-bold text-amber-300'},
      {label:'Private Secure Server', className:'font-bold text-amber-300'},
      {label:'End-to-end Encryption', className:'font-bold text-amber-300'},
      {label:'Free Hybrid Hosting', className:'font-bold text-amber-300'}
    ],
    featured: true,
    button: 'Buy now',
    buttonClasses: 'cta',
    buttonTarget: '/buy'
  },
]


export default function PricingPage() {
  const root = useClasses()
  const location = useLocation()
  const [mode, setMode] = useQueryParam('mode', 'oo')
  const { country_code:country } = location
  console.log(country)

  return (
    <Layout short>
      <SEO title='Plans' description='Overview of OneOffice pricing plans'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Plans</h1>
        <h2 className="subtitle">Simple, no hidden-fees, 'all-in' pricing</h2>

        <div className="subheading mt-20">OneOffice</div>
        <div className="heading">Productivity & Collaboration</div>
        <div className="description">
          <p>
            OneOffice is the modern solution that is well-built and fully optimized to run at the speed of your business. 
          </p>
          <p>
            <Link to='/savings' className='basic font-bold text-orange-600 hocus:text-primary'>You can estimate your costs using our interactive calculator</Link>.
          </p>
          <p>
            We also provide our next-generation ERP which competes directly with the legacy big names while utilizing modern technology stack (<Link to='/contact'>contact us</Link> for more information).
          </p>
        </div>

        <div className='bg-gradient-to-br from-sky-600 to-teal-300 shadow py-6 md:py-15 my-15 rounded-xl'>
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-xl md:text-2xl xl:text-4xl font-bold text-white mb-5 md:mb-7">Show plans from ...</h2>
            <div className="toggle-button-group" role="group">
              <button
                type="button"
                onClick={() => setMode('oo')}
                className={"toggle-button" + (mode == 'oo' ? ' active' : '')}
              >
                <img className='w-8 h-8 inline md:-mt-1' src={OO}/>
                <span className='hidden md:inline md:pl-2'>OneOffice</span>
              </button>
              <button
                type="button"
                onClick={() => setMode('ms')}
                className={"toggle-button" + (mode == 'ms' ? ' active' : '')}
              >
                <img className='w-8 h-8 inline md:-mt-1' src={MS}/>
                <span className='hidden md:inline md:pl-2'>MS365</span>
              </button>             
              <button
                type="button"
                onClick={() => setMode('google')}
                className={"toggle-button" + (mode == 'google' ? ' active' : '')}
              >
                <img className='w-8 h-8 inline md:-mt-1' src={GOOGLE}/>
                <span className='hidden md:inline md:pl-2'>Google</span>
              </button>  
            </div>           
          </div>
        </div>

        <Plans plans={mode == 'oo' ? OO_PLANS : mode == 'ms' ? MS_PLANS : GOOGLE_PLANS} hidePrices={['PK','PAK'].includes(country)}/>

        <div className="subheading mt-30" id="faqs">Pricing FAQs</div>
        <div className="heading">Answers to Common Plan Questions</div>
        <div className="description">
          For more answers please visit the <Link to='/faqs'>complete FAQs</Link> or just <Link to='/contact'>drop us a line</Link>.
        </div>

        <FAQs faqs={FAQS}/>

        <CTA variant={5}/>

      </main>
    </Layout>
  )
}
