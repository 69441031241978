import { addCss } from '../lib/ReactUtils'

addCss(`
.toggle-button-group {
  @apply flex shadow
}
.toggle-button {
  @apply text-lg md:text-xl lg:text-2xl px-5 md:px-7 py-3 bg-blue-100 text-primary font-light leading-tight uppercase hover:primary-dark transition duration-150 ease-in-out border-gray-300 border-r-2 first:rounded-l last:rounded-r last:border-0
}
.toggle-button.active {
  @apply bg-orange-500 hover:bg-orange-500 cursor-default text-white border-orange-500 font-semibold
}
`)