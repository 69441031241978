import React, { Fragment } from 'react'
import '../styles/Plans'
import { Link } from 'gatsby'
import { useClasses } from '../lib/ReactUtils'


export default function Plans({plans, hidePrices}) {
  console.log(hidePrices)
  const root = useClasses()
  if (!plans) return null
  return (
    <div className='plans-container' ref={root}>
      {plans.map((plan, index) => (
        <div key={index} className={'plans-plan' + (plan.featured ? ' plans-plan-featured' : '')}>
          { !plan.featured && <div className={'plans-plan-highlight bg-gradient-to-r ' + (index == 0 ? 'from-green-500 to-green-200' : 'from-red-400 to-red-200')} /> }
          <div className='plans-plan-header'>
            <span className='plans-plan-name'>{plan.name}</span>
            { !hidePrices && <span className='plans-plan-price'>{plan.price}</span> }
            <span className='plans-plan-duration'>{plan.duration}</span>
          </div>
          <div className='plans-plan-features'>
            <span className='plans-plan-feature-main'>{plan.mainFeature}</span>
            {plan.features.map((feature, index) => (
              <Fragment key={index}>
                { typeof feature == 'string' ? 
                  <span className='plans-plan-feature'>{feature}</span> :
                  <span className={feature.className + ' plans-plan-feature'}>{feature.label}</span>
                }
              </Fragment>
            ))}
          </div>
          { plan.button &&
            <div className='plans-plan-action'>
              <Link className={'button ' + plan.buttonClasses } to={plan.buttonTarget}>{plan.button}</Link>
            </div>
          }
        </div>
      ))}
    </div>
  )
}
