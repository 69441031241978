import React, { useState } from 'react'
import { useClasses } from '../lib/ReactUtils'
import '../styles/FAQs'

export default function FAQs({faqs, className = ''}) {
  if (!faqs) faqs = []
  const root = useClasses()
  const length0 = Math.ceil(faqs.length / 2)
  const length1 = Math.floor(faqs.length / 2)

  const toggle = (index) => {
    if (index == selected) setSelected(null)
    else setSelected(index)
  }

  const [selected, setSelected] = useState()
  return (
    <div className={'faqs-container ' + className} ref={root}>
      <div className='faqs-col'>
        {[...Array(length0)].map((x, index) => (
          <div className='faqs-faq' key={index} onClick={() => toggle(2*index)}>
            <div className='faqs-question'>
              <div className='faqs-question-text'>{faqs[2*index].question}</div>
              <span className='faqs-question-icon'>
                <svg className={'faqs-question-icon-svg' + (selected == 2*index ? '-selected' : '')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
              </span>
            </div>
            <div className={'faqs-answer' + (selected == 2*index ? '-selected' : '')}>{faqs[2*index].answer}</div>
          </div>
        ))}
      </div>
      <div className='faqs-col'>
        {[...Array(length1)].map((x, index) => (
          <div className='faqs-faq' key={index} onClick={() => toggle(2*index+1)}>
            <div className='faqs-question'>
              <div className='faqs-question-text'>{faqs[2*index+1].question}</div>
              <span className='faqs-question-icon'>
                <svg className={'faqs-question-icon-svg' + (selected == 2*index+1 ? '-selected' : '')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
              </span>
            </div>
            <div className={'faqs-answer' + (selected == 2*index+1 ? '-selected' : '')}>{faqs[2*index+1].answer}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
